import { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import CellTitle, { CellTitleLine } from '../../components/CellTitle';
import NavLink from '../../components/NavLink';
import CarouselContent from './CarouselContent';
import CountContent from './CountContent';
import { httpPost } from "../../services/request";
import { viewPortWidth } from '../../utils/util';
import "./index.scss";
import 'swiper/css';

const HomeProduct = (props: { content: string, url: string, to: string }) => {
    const { content, url, to } = props;
    const onRedirect = () => {
        window.location.href = to;
    }
    return (<div className='home-page-product' onClick={onRedirect}>
        <img src={url} alt="" />
        <div>{content}</div>
    </div>)
}

function Home() {
    const [newsData, setNewsData] = useState<any>({});
    useEffect(() => {
        //首页需要注册移入导航栏增加透明色
        httpPost('api-cms/open/gw_open/home_page_news', { 'status': 1, 'showIndex': 1 }).then(response => {
            if (response?.code === 0) {
                setNewsData(response.data);
            }
        })
    }, [])
    const onPlay = () => {
        let width = viewPortWidth();
        if(width<=768){
            window.location.href = "https://res.whalecharge.com/video/introduce.mp4";
            return;
        }
        let eleVideo: any = document.getElementById("jc-video")
        if (eleVideo.requestFullscreen) {
            eleVideo.requestFullscreen();

        }
        else if (eleVideo.mozRequestFullScreen) {
            eleVideo.mozRequestFullScreen();

        } else if (eleVideo.webkitRequestFullScreen) {
            eleVideo.webkitRequestFullScreen();


        } else if (eleVideo.msRequestFullscreen) {
            eleVideo.msRequestFullscreen();
        }
        setTimeout(function () {
            // safari 需要延时
            eleVideo.play();
        }, 500);
    }
    return (
        <>
          {/* @ts-ignore */}
          <video id='jc-video' preload="metadata" poster="" x5-video-player-fullscreen="true" x5-playsinline="" playsInline={true} webkit-playsinline="true">
                <source src="https://res.whalecharge.com/video/introduce.mp4" type="video/mp4" />
                您的浏览器不支持Video标签。
            </video>
            <div className='home-page'>
                <div className='home-page-banner'>
                    <div className='home-page-banner_content'>
                        <div className='home-page-banner_content--f1'>
                            {/* @ts-ignore */}
                            <span>以<span className='bold'>科技</span>创新</span>
                            <span>与<span className='bold'>万物</span>共生</span>
                        </div>
                        <div className='home-page-banner_content--f2'>Innovated by technology, symbiosis with all creatures.</div>
                        <div className='home-page-banner_content--f3'>
                            以优质的充电服务、成熟的解决方案， 成为您的新能源伙伴
                        </div>
                        <NavLink to="/about/list" className='home-page-banner_content--f4'>了解更多</NavLink>
                    </div>
                </div>
                <div className='home-page-video' onClick={onPlay}>
                    <div className='home-page-video--bg'></div>
                    <img className='play' src="/image/global/img_play.png" />
                    <img className='title' src="/image/home/img_anounce.png" />
                    <div><CountContent /></div>
                </div>
                <div className='page-full'>
                    <CellTitle otherWrapperClass='home-page-link' title="主要产品" english="MAIN PRODUCTS" other={<NavLink to="/product/list">查看全部</NavLink>} />
                    <div className='d-none d-md-block'>
                        <div className='home-page-products'>
                            <HomeProduct to="/product/detail/0?redirect=2" url="/image/products/img_product1.png" content="7kW/21kW基斯卡交流桩" />
                            <HomeProduct to="/product/detail/4?redirect=2" url="/image/products/img_product2.png" content="120kW塞鲸双枪直流充电桩" />
                            <HomeProduct to="/product/detail/8?redirect=2" url="/image/products/img_product3.png" content="600kW液冷超充终端" />
                        </div>
                    </div>
                </div>
                <div className="home-page-swiper d-md-none">
                    <Swiper
                        slidesPerView={2}
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                    >
                        <SwiperSlide>
                            <HomeProduct to="/product/detail/0?redirect=2" url="/image/products/img_product1.png" content="7kW/21kW基斯卡交流桩" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <HomeProduct to="/product/detail/4?redirect=2" url="/image/products/img_product2.png" content="160kW塞鲸双枪直流充电桩" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <HomeProduct to="/product/detail/8?redirect=2" url="/image/products/img_product3.png" content="600kW液冷超充终端" />
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className='home-page-news-wrapper'>
                    <CellTitle title="新闻中心" otherWrapperClass='home-page-link' english="NEWS CENTER" other={<NavLink to="/news">查看全部</NavLink>} />
                    <div className='home-page-carousel'>
                        <Carousel data-bs-theme="dark" prevIcon={null} nextIcon={null} touch>
                            {
                                newsData?.upList?.map((item: any, index: number) => (<Carousel.Item key={index}>
                                    <CarouselContent linkUrl={`/news/detail/${item.id}?redirect=2`} data={item} url={String(item.picList)} />
                                </Carousel.Item>))
                            }
                        </Carousel>
                    </div>
                </div>
                <div className='home-page-solution-wrapper'>
                    <CellTitle title="解决方案" english="TOTAL SOLUTION" />
                    <div className='d-flex justify-content-center px-3 px-md-0'>
                        <CellTitleLine otherWrapperClass="home-page-solution-link" title='鲸充全栈式智能充电解决方案'
                            lineDirection='right'
                            subTitle={<NavLink to="/solution">了解详情</NavLink>}
                            content={<div className='home-page-solution__content my-3'>目前公共充电设施总量不足，现有设施利用率又比较低，经常呈现出“有车无桩”和“有桩无车”的尴尬局面。为解决行业痛点，鲸充不断探索，升 级车位级充电运营解决方案，以行业领先思维，提供更便捷、更智能、更高效的全栈式“停车+充电+配套+运营”定制化服务，为车主提供便捷， 让客户实现营收，让新能源汽车充电不再难！</div>}
                        />
                    </div>
                </div>
                <img src="/image/solution/img_solution_pc.png" className='d-none d-md-block home-page-solution__img' alt="" />
                <img src="/image/solution/img_solution_app.png" className='d-block d-md-none home-page-solution__img' alt="" />
                {/* <div className='px-3 home-page-solution__description'>
                    <div>鲸充将为您提供更加完善更加优质的定制化服务</div>
                    <div className='home-page-solution__description-block'>从「规划建站」——「落地运营」全程指导</div>
                </div> */}
            </div></>);
}

export default Home;