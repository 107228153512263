import { CellTitleHead } from '../../../components/CellTitle';
import HeadWrapper from '../../../components/HeadWrapper';
import Join1Png from '../../../assets/join/join1.png';
import Join2Png from "../../../assets/join/join2.png";
import Join3Png from "../../../assets/join/join3.png";
import Join4Png from "../../../assets/join/join4.png";
import Join5Png from '../../../assets/join/join5.jpg';
import Join6Png from "../../../assets/join/join6.jpg";
import JoinBgImg from "../../../assets/join/img_join-bg.png";
import "./index.scss";

interface JoinCardProps {
    imgUrl: any;
    title: string;
}

function JoinCard(props: JoinCardProps) {
    const { imgUrl, title } = props;
    return (<div className='join-card'>
        <img src={imgUrl} alt=""/>
        <div className='join-card-title'>{title}</div>
    </div>)
}

function Join() {
    return (<div className="join-page">
        <HeadWrapper ptNone className='join-page-wrapper  m-page-t' imgUrl={JoinBgImg} title={<CellTitleHead className='join-page-title' title="加入我们" english="WELCOME TO JOIN US" suffix={<a href="/about/join/detail">了解更多</a>}>
            <p>鲸充新能源，创立于2019年3月，总部位于南京，是一家集智能充电设备研发、制造、销售；充电网的投建及运营；汽车充电大数据挖掘和增值服务于一体的高新技术企业。
            </p>
            <p> 我们有一支富有朝气、锐意创新、爱岗敬业、敢于担当的团队，目前200余人！期待更多优秀人才的加入！
            </p>
            <p>
                公司上升通道宽广，氛围友好活泼。鲸充具备完善的员工福利制度，五险一金、周末双休、带薪年假、节日福利、交通补助、全勤奖金……除此以外，我们还有健全的新员工入职培训管理和团建活动，工作累了还可以去公司健身房撸个铁，最快时间带你融入鲸充大家庭！
            </p>
        </CellTitleHead>}>
            <div className='join-page-card'>
                <JoinCard imgUrl={Join1Png} title="徒步登山公益行" />
                <JoinCard imgUrl={Join2Png} title="鲸充羽毛球比赛" />
                <JoinCard imgUrl={Join3Png} title="鲸充生日会" />
                <JoinCard imgUrl={Join4Png} title="元宵节猜灯谜" />
                <JoinCard imgUrl={Join5Png} title="鲸充员工内部培训" />
                <JoinCard imgUrl={Join6Png} title="鲸充员工健身房" />
            </div>
        </HeadWrapper>
    </div>)
}

export default Join;