import axios from 'axios';

axios.defaults.baseURL = 'https://gateway.whalecharge.com';

export const httpGet = async (url:string, params:any) => {
    const response = await axios.get(url, { params });
    if (response?.status === 200) {
        return response.data
    }
}
export const httpPost = async (url:string, params:any) => {
    const response = await axios.post(url, params);
    if (response?.status === 200) {
        return response.data
    }
}
