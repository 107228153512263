import React, { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import BasicLayout from './layout';
import { BrowserRouter, useLocation } from 'react-router-dom';

const AutoScrollTop = (({ children }: any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    let rememberScroll = sessionStorage.getItem("remember-scroll");
    if(rememberScroll!=='2'){
      document.documentElement.scrollTo(0, 0);
    }
    sessionStorage.removeItem("remember-scroll")
  }, [location.pathname]);
  return children;
})


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AutoScrollTop>
        <App />
      </AutoScrollTop>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
