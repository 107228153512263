import './App.scss';
import { Route, Routes } from 'react-router-dom';
import routes from "./router";
import BasicLayout from "./layout";
import renderRoutes from './utils/renderRoutes';



function App() {
  let dynamicRoutes = renderRoutes(routes);
  return (
    <Routes>
      <Route element={<BasicLayout />}>
        {
          dynamicRoutes.map((item,index)=>(<Route key={index} path={item.path} element={item.element}/>))
        }
      </Route>
    </Routes>
  );
}

export default App;
