import { useState } from 'react';
import CellTitle, { CellTitleHead } from "../../../components/CellTitle";
import HeadWrapper from '../../../components/HeadWrapper';
import JoinDetailBg from '../../../assets/join/img_join-bg1.png';
import Tab from "../../../components/Tab";
import ReturnBack from '../../../components/ReturnBack';
import "./index.scss";
import classNames from 'classnames';
interface JoinPosionCardProps {
    className?: string;
    data: any;
    onClick?: () => void;
}

const JoinPosionCard = (props: JoinPosionCardProps) => {
    const { className, data } = props;
    const onRedirect = (id:string)=>{
        window.location.href = `/about/join/detail/${id}`;
    }
    return (<div className={classNames("join-positon-card", className)} onClick={() => onRedirect(data.id)}>
        <h5>{data.position}</h5>
        <div className='join-positon-card--tag'>
            <div>{data.depart}</div>
            <div>{data.solary}</div>
            <div>{data.city}</div>
        </div>
        <div className='join-positon-card--date'>
            {data.date}
        </div>
    </div>)
}

function JoinDetail() {
    const [tabKey, setTabKey] = useState(0);
    const onRedirect = (id:string | number)=>{
        window.location.href = `/about/join/detail/${id}`
    }
    return (<><div className="join-detail-page">
        <HeadWrapper className='join-detail-page-wrapper' imgUrl={JoinDetailBg} title={<CellTitleHead title="加入我们" english="WELCOME TO JOIN US" className="align-items-center join-detail-page-content--detail">
            <p>
                如果你，不甘才华被埋没，想寻找一个可以释放激情的工作。
            </p>
            <p> 如果你，怀揣一颗不甘平凡的心，想拥有一个富有创造力和自由思想的平台。
            </p>
            <p>
                加入鲸充，你将和有趣的同事并肩，遇到才华横溢的领路人！收入和能力成正比，你的能力和创意将受到重视！
            </p>
            <p>
                这里是施展才华的地方，你的想法都有实现的可能！期待你加入鲸充，实现人生价值！
            </p>
        </CellTitleHead>}>
            <>
                <CellTitle title="招聘职位" size="small" english="Recritment Position" englishStyle={{ color: "#222" }} />
                <Tab onChange={(key) => { setTabKey(key) }} data={[{ key: 0, title: '产品研发类' }, { key: 1, title: '销售服务类' }]} activeKey={tabKey} />
                {
                    tabKey === 0 && (<div className='table-wrapper mb-5 d-none d-md-block'>
                        <table>
                            <thead>
                                <tr>
                                    <th>职位</th>
                                    <th>部门类别</th>
                                    <th>工作地点</th>
                                    <th>发布时间</th>
                                    <th>薪资待遇</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr onClick={() => { onRedirect(0)}}>
                                    <td>嵌入式软件工程师</td>
                                    <td>研发部</td>
                                    <td>南京</td>
                                    <td>2023-10-27</td>
                                    <td>20-25k</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>)
                }
                {
                    tabKey === 1 && (<div className='table-wrapper mb-5 d-none d-md-block'>
                        <table>
                            <thead>
                                <tr>
                                    <th>职位</th>
                                    <th>部门类别</th>
                                    <th>工作地点</th>
                                    <th>发布时间</th>
                                    <th>薪资待遇</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr onClick={() => { onRedirect(1) }}>
                                    <td>充电桩销售经理</td>
                                    <td>销售部</td>
                                    <td>南京</td>
                                    <td>2023-10-27</td>
                                    <td>10-15k</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>)
                }
                {
                    tabKey === 0 && (<div>
                        <JoinPosionCard data={{
                            position: "嵌入式软件工程师",
                            depart: '研发部',
                            solary: "20-25k",
                            city: "南京",
                            date: '2023-10-27',
                            id: 0
                        }} className='d-block d-md-none' />
                    </div>)
                }
                {
                    tabKey === 1 && (<div>
                        <JoinPosionCard data={{
                            position: "充电桩销售经理",
                            depart: '销售经理',
                            solary: "10-15k",
                            city: "南京",
                            date: '2023-10-27',
                            id: 1
                        }} className='d-block d-md-none' />
                    </div>)
                }</>
        </HeadWrapper>
    </div>
        <ReturnBack />
    </>)
}

export default JoinDetail;