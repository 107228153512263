import { Nav } from 'react-bootstrap';
import classNames from 'classnames';
import "./index.scss";

type TabItem = {
    key: number;
    title: string;
}

interface TabProps {
    data: TabItem[];
    onChange?: (key: number) => void;
    activeKey?: number;
    className?: string;
}

function Tab(props: TabProps) {
    const { data, onChange, activeKey, className } = props;
    const onSwitch = (key: number) => {
        onChange?.(key)
    }
    let rest: any = {};
    if (activeKey !== undefined) {
        rest["activeKey"] = `#${activeKey}`;
    }
    else {
        rest['defaultActiveKey'] = `#${data[0].key}`;
    }
    return <div className={classNames('jc-tab', className)}>
        <Nav variant="underline" {...rest}>
            {
                data.map(item => (<Nav.Item key={item.key}><Nav.Link onClick={() => onSwitch(item.key)} href={`#${item.key}`}>{item.title}</Nav.Link></Nav.Item>))
            }
        </Nav>
    </div>
}

export default Tab;