import { RouteObjectExtend } from '../router';

function renderRoutes(routes:RouteObjectExtend[], routeEle?:any[]){
    routeEle || (routeEle = []);
    for(let i=0;i<routes.length;i++){
        let item = routes[i];
        item.element && (routeEle.push(item));
        if(Array.isArray(item.children) && item.children.length){
            renderRoutes(item.children,routeEle);
        }
    }
    return routeEle;
}

export default renderRoutes;