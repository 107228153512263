import { useCallback } from 'react';
import type { PropsWithChildren } from 'react';
import classNames from 'classnames';
import "./index.scss";
interface NavLinkProps extends PropsWithChildren{
    to:string;
    className?:string;
}

function NavLink(props:NavLinkProps){
    const { to, children, className } = props;
    const onRedirect = useCallback(()=>{
        window.location.href = to;
    },[])
    return <a onClick={onRedirect} className={classNames("jc-nav-link",className)}>
        {children}
    </a>
}

export default NavLink;