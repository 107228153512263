import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CellTitleHead, CellTitleLine } from "../../../components/CellTitle";
import HeadWrapper from '../../../components/HeadWrapper';
import JoinEmplyeeBg from "../../../assets/join/img_join-bg1.png";
import ReturnBack from '../../../components/ReturnBack';
import "./index.scss";
import BaseData from './data.json';
import Popover from '../../../components/Popover';


function JoinDetailEmployee() {
    const params: any = useParams();
    const [visible, setVisible] = useState(false);
    return (<div className='join-employ-page'>
        <HeadWrapper className='join-employ-page-wrapper' imgUrl={JoinEmplyeeBg} title={<CellTitleHead title="加入我们" english="WELCOME TO JOIN US" className="align-items-center join-employ-page-title"/>}>
            <div>
            <div className="join-employ-page-content__info mt-4">
            <div className="join-employ-page-content__info--main">
                <span>{BaseData[params.id].position}</span>
                <span>{BaseData[params.id].solary}</span>
            </div>
            <div className="join-employ-page-content__info--tag">
                <div>{BaseData[params.id].city}</div>
                <div>{BaseData[params.id].positionDes}</div>
                <div>{BaseData[params.id].depart}</div>
            </div>
            <CellTitleLine title="岗位职责" size="middle" lineDirection="left" className="mb-3" />
            <div className="join-employ-page-content--require">
                {
                    BaseData[params.id].positionDuty.map((item, index) => (<p key={index}>{item}</p>))
                }
            </div>
            <CellTitleLine title="任职要求" size="middle" lineDirection="left" className="mb-3 mt-4" />
            <div className="join-employ-page-content--require">
                {
                    BaseData[params.id].positionCondition.map((item, index) => (<p key={index}>{item}</p>))
                }
            </div>
            <div className='join-employ-page-content--wrapper'>
                <div className='join-employ-page-content--btn' onClick={()=>{setVisible(true)}}>投递</div>
                <Popover visible={visible} onCancel={()=>{setVisible(false)}} className='join-employ-page-content--wrapper__popover'>
                    <div className='detail'>
                        <div><span>联系电话：</span>17712423606</div>
                        <div><span>简历投递邮箱：</span><br/>hr@whalecharge.com</div>
                        <div><span>联系地址：</span><br/>南京江宁绿地城际空间站H2幢1201室</div>
                    </div>
                </Popover>
            </div>

        </div>
        <ReturnBack />
            </div>
        </HeadWrapper>
    </div>)
}

export default JoinDetailEmployee;