import { useParams } from 'react-router-dom';
import ReturnBack from '../../../components/ReturnBack';
import HeadWrapper from '../../../components/HeadWrapper';
import DataSource from './data.json';
import "./index.scss";

const ProductContentTitle = ({ title }: { title: string }) => {
    return (<div className='product-detail-page-title'>{title}</div>)
}

function ProductContent({ id }: { id?: string | number }) {
    const params: any = useParams();
    let idNo = id ?? params.id;
    const dataItem = DataSource[idNo];
    return (<div className="product-detail-page">
        <HeadWrapper className={idNo=='8'?"special":""} imgUrl={dataItem.bgUrl} title={<ProductContentTitle title={dataItem.title} />}>
            <>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <img src={dataItem.contentLeftUrl} alt="" style={{ width: '100%' }} />
                    </div>
                    <div className='col-12 col-md-6'>
                        <img src={dataItem.contentRightUrl} alt="" style={{ width: '100%' }} />
                    </div>
                </div>
                <ReturnBack />
            </>
        </HeadWrapper>
    </div>)
}

export default ProductContent;