import { ReactNode, useEffect, useMemo, useState, useRef } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Outlet, useLocation } from 'react-router-dom';
import MenusPc from '../components/MenusPc';
import FloatDot from '../components/FloatDot';
import Footer from '../components/Footer';
import $ from 'jquery';
import classnames from 'classnames';
import routes from '../router';
import renderMenu from './renderMenu';
import { viewPortWidth, viewPortHeight } from '../utils/util';
import { addRouteRecord } from '../utils/util';

function RenderOutlet({ children }: { children: ReactNode }) {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 300)
    }, [location.pathname])
    if (loading) return <div />
    return <div>{children}</div>
}

function BasicLayout(props: any) {
    const location = useLocation();
    const [ignoreTop, setIgnoreTop] = useState(true);
    const [isBasic, setIsBasic] = useState(location.pathname != "/");

    useEffect(() => {
        if (viewPortWidth() > 768) {
            //@ts-ignore
            $(".nav-root").get(0).dataset.area = "off";
            $(".nav-root").removeClass('filter-backdrop').removeClass("scroll");
        }
        $(window).on("scroll", () => {
            let scrollTop: number = $(window).scrollTop()! as number;
            let height = viewPortHeight();
            let width = viewPortWidth();
            let area = $(".nav-root").get(0)?.dataset.area;
            if (scrollTop >= height) {
                if (width > 768) {
                    if (area === 'off') {
                        $(".nav-root").addClass('filter-backdrop')
                    }
                    $(".nav-root").addClass('scroll');
                }
            }
            else {
                if (area === 'off' && width > 768) {
                    if (area === 'off') {
                        $(".nav-root").removeClass('filter-backdrop')
                    }
                    $(".nav-root").removeClass("scroll");
                }
            }
            if (scrollTop > 0) {
                setIgnoreTop(false)
            }
            else {
                setIgnoreTop(true);
            }
        })
        $("#responsive-navbar-nav>.navbar-nav>.dropdown").on("click", function (e) {
            let width = viewPortWidth();
            if (width > 768) {
                let type = e.currentTarget.dataset.type;
                //隐藏菜单框
                $(".menus-pc").removeClass("menus-pc-show").addClass("menus-pc-hidden");
                $(".nav-root").addClass('filter-backdrop');
                let item = routes.slice(1).find(t => t.path.includes(type));
                window.location.href = item.children[0].path;
            }
        })
    }, [])
    useEffect(() => {
        //记录最新两次路由记录
        addRouteRecord(location.pathname);
    
        setIgnoreTop(true);
        if (location.pathname.length == 1) {
            setIsBasic(false)
        }
        else {
            setIsBasic(true);
        }
    }, [location.pathname])
    function onMenuMouseEnter(e: any) {
        if(sessionStorage.getItem("inout")==="1") return;
        sessionStorage.setItem('inout',"1");
        let type = $(e.target).data('type');
        !type && (type = $(e.target).parent().data('type'));
        let singleMenus = ["home"]
        if (!$(`.${type}`).hasClass("menus-pc-show")) {
            $(".menus-pc").removeClass("menus-pc-show").addClass("menus-pc-hidden");
            $(`.${type}`).removeClass("menus-pc-hidden").addClass("menus-pc-show");
            $('.nav-root').addClass('other-route-nav--offshadow');
            $('#responsive-navbar-nav').find(".active").removeClass("active");
            $(".filter-backdrop-none").addClass("filter-backdrop-event");
            if (!singleMenus.includes(type)) {
                $(e.target).addClass("active");
            }
        }
    }
    function onMenuMouseLeave(){
        sessionStorage.setItem('inout',"0");
    }
    const menus = useMemo(() => renderMenu(routes, 1, location, onMenuMouseEnter, onMenuMouseLeave), [location.pathname]);
    return (
        <>
            <Navbar collapseOnSelect expand="md" className={classnames("nav-root filter-backdrop", { "other-route-nav": isBasic })} fixed="top">
                <Container data-type="vd6735231">
                    <Navbar.Brand href="/"><img src="/logo-light.png" alt="" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                </Container>
                <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end d-md-none'>
                    <Nav>
                        {
                            menus
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <MenusPc isBasic={isBasic} />
            <Outlet />
            <Footer isBasic={isBasic} />
            <FloatDot ignoreTop={ignoreTop} />
        </>)
}

export default BasicLayout;