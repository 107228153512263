// import { NavLink } from 'react-router-dom';
import NavLink from "../NavLink";
import routes from "../../router";
import classNames from 'classnames';

//仅仅限于两级
function renderMenu(isBasic?: boolean) {
    let routeMenus: any[] = [];
    for (let i = 0; i < routes.length; i++) {
        let item = routes[i];
        if(item.path!.indexOf("product")>-1) continue;
        if (Array.isArray(item.children) && item.children?.length > 0) {
            routeMenus.push(<div key={item.path} className={classNames(`menus-pc menus-pc-hidden d-none d-md-block ${item.path!.replace(/\//g, "")}`, { "other-route-nav": isBasic })}>
                <div className='menus-pc-child menus-pc-child__render'>
                    <ul>
                        {
                            item.children.map((t: any) => {
                                if (t.hidden !== true) {
                                    return (<li key={t.path}><NavLink to={t.path!}>{t.title}</NavLink></li>)
                                }
                                return null;
                            })
                        }
                    </ul>
                </div>
            </div>)
        }
    }
    return routeMenus
}

export default renderMenu;