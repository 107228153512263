import type { ReactNode } from 'react';
import classNames from "classnames";
import "./index.scss";

interface PopoverProps{
    className?:string;
    children?:ReactNode;
    visible?:boolean;
    onCancel?:()=>void;
}

function Popover(props:PopoverProps){
    const { className, children, visible, onCancel } = props;
    return (<div className={classNames("jc-bubble",className,{"jc-bubble-hidden":!visible})}>
    <div className="jc-bubble-word">{children}</div>
    <div className="jc-bubble-over"/>
    <div className='jc-bubble-close' onClick={onCancel}>
        <img src="/image/global/img_close.png"/>
    </div>
  </div>)
}

export default Popover;