import { cloneElement } from 'react';
import type { PropsWithChildren, ReactNode, CSSProperties } from 'react';
import classNames from 'classnames';
import "./index.scss";

interface CellTitleProps {
    title: string;
    english: string;
    other?: ReactNode;
    englishStyle?:CSSProperties;
    size?:'small';
    otherWrapperClass?:string;
}

function CellTitle(props: CellTitleProps) {
    const { title, english, other, englishStyle, size, otherWrapperClass } = props;
    return (<div className={classNames('cell-title',{[`cell-title--${size}`]:size})}>
        <h3>{title}</h3>
        <div className='cell-title-english' style={englishStyle}>{english}</div>
        {other && <div className={classNames('cell-title-other', otherWrapperClass)}>{other}</div>}
    </div>)
}

interface CellTitleLineProps {
    title: string;
    subTitle?: ReactNode;
    content?:ReactNode;
    lineDirection?:"left" | "right";
    size?:"small" | "middle" | "large";
    className?:string;
    caret?:boolean;
    caretDirection?:'up' | 'down'
    onClick?:()=>void;
    otherWrapperClass?:string;
}


export function CellTitleLine(props: CellTitleLineProps) {
    const { title, subTitle, content, lineDirection, size, className, caret,otherWrapperClass, caretDirection, onClick } = props;
    return (<div className={classNames('cell-title-line',{
        [`cell-title-line__${lineDirection}`]:lineDirection,
        [`cell-title-line__${size}`]:size
        }, className)}>
        <div className='cell-title-line__title'>
            <div onClick={onClick} className={classNames('main',
            {
                ["main-caret"]:!!caret,
                [`main-caret-${caretDirection}`]:caret && caretDirection
            }
            )}>{title}</div>
            {
                subTitle && (<div className={classNames('sub',otherWrapperClass)}>{subTitle}</div>)
            }
        </div>
        {content}
    </div>)
}

interface CellTitleHeadProps extends PropsWithChildren<any>{
    title:ReactNode;
    english:ReactNode;
    className?:string;
}

export function CellTitleHead(props:CellTitleHeadProps){
    const { title, english, children, suffix, className } = props;
    return (<div className={classNames('cell-title-head',className)}>
        <div className='cell-title-head__main'>
            {title}
            {suffix && cloneElement(suffix,{className:'cell-title-head__main-suffix'})}
        </div>
        <div className='cell-title-head__english'>{english}</div>
        {children}
    </div>)
}


export default CellTitle;