import classNames from "classnames";
import $ from "jquery";
import "./index.scss";

interface FloatDotProps {
    ignoreTop?: boolean;
}

function FloatDot(props: FloatDotProps) {
    const { ignoreTop } = props;
    const scrollTop = () => {
        $(window).scrollTop(0);
    }
    const onOpen = (url: string) => {
        window.open(url, "_blank")
    }
    return (<div className="float-dot d-none d-md-block">
        <div className="float-dot-1">
            <div></div>
            <div className="code">
                <img src="/image/dot/dot1.png" alt="" onClick={() => { onOpen('https://mall.jd.com/index-12642055.html') }} />
            </div>
        </div>
        <div className="float-dot-2">
            <div></div>
            <div className="code">
                <img src="/image/dot/dot2.png" alt="" onClick={() => onOpen('https://shop351254683.taobao.com/shop/view_shop.htm?spm=a21n57.1.0.0.371f523cL2NNek&appUid=RAzN8HWXWhhez91rhaQn7MY3MWKjUqfJQLFAQ19ynFBHMUAX7Vh#/')} />
            </div>
        </div>
        <div className="float-dot-3">
            <div></div>
            <div className="code">
                <img src="/image/dot/dot3.png" alt="" />
            </div>
        </div>
        <div className="float-dot-4">
            <div></div>
            <div className="code">
                <img src="/image/dot/dot4.png" alt="" />
            </div>
        </div>
        <div className="float-dot-5">
            <div></div>
            <div className="code">
                <img src="/image/dot/dot5.jpg" alt="" />
            </div>
        </div>
        <div onClick={scrollTop} className={classNames("float-dot-6", { "float-dot-hidden": ignoreTop })}>
            <div />
        </div>
    </div>)
}

export default FloatDot;