import { useEffect, useMemo, useRef } from 'react';
// import { NavLink } from 'react-router-dom';
import NavLink from '../NavLink';
import $ from 'jquery';
import classNames from 'classnames';
import renderMenu from './renderMenu';
import { viewPortWidth } from '../../utils/util';
import "./index.scss";

function MenusPc({ isBasic }: { isBasic?: boolean }) {
    const realMoveRef = useRef<boolean>(true);
    const menus = useMemo(() => renderMenu(isBasic), [isBasic])
    useEffect(() => {
        $(".nav-root").on("mouseenter", () => {
            if (viewPortWidth() > 768) {
                realMoveRef.current = false;
                //@ts-ignore
                $(".nav-root").get(0).dataset.area = "on"
                $(".nav-root").addClass('filter-backdrop');
            }
        }).on('mouseleave', () => {
            setTimeout(() => {
                if (realMoveRef.current !== true && viewPortWidth() > 768) {
                    //@ts-ignore
                    $(".nav-root").get(0).dataset.area = "off";

                    if (!$(".nav-root").hasClass("scroll")) {
                        $(".nav-root").removeClass('filter-backdrop');
                    }

                    $('.nav-root').find(".nav-link").removeClass('active');
                    $(".menus-pc").removeClass("menus-pc-show").addClass("menus-pc-hidden");
                    $('.nav-root').removeClass('other-route-nav--offshadow');
                }
            }, 80)
        })
        $(".menus-pc").on('mouseleave', () => {
            setTimeout(() => {
                if (realMoveRef.current && viewPortWidth() > 768) {
                    $('#responsive-navbar-nav').find(".active").removeClass("active");
                    $(".menus-pc").removeClass("menus-pc-show").addClass("menus-pc-hidden");
                    $('.nav-root').removeClass('other-route-nav--offshadow');
                    //@ts-ignore
                    $(".nav-root").get(0).dataset.area = "off";
                    if (!$(".nav-root").hasClass("scroll")) {
                        $(".nav-root").removeClass('filter-backdrop');
                    }
                    $(".nav-root").find(".nav-link").removeClass('active');
                }
            }, 80)
        }).on("mouseenter", () => {
            realMoveRef.current = true;
        })
    }, [])
    return (<>
        {menus}
        <div className={classNames('menus-pc menus-pc-hidden d-none d-md-block product', { "other-route-nav": isBasic })}>
            <div>
                {/* <div className='menus-pc-child menus-pc-child__main'>
                    <div>
                        <NavLink to="/product/list">主营产品中心</NavLink>
                    </div>
                </div> */}
                <ul className='menus-pc-child'>
                    <li>
                        <h3>
                            国标交、直流桩/堆
                        </h3>
                        <ul className='menus-pc-child__content'>
                            <li>
                                <h4>交流桩</h4>
                                <ul className='menus-pc-child__content--detail'>
                                    <li><NavLink to="/product/detail/0">基斯卡7/21kW</NavLink></li>
                                    <li><NavLink to="/product/detail/1">瓦吉塔7kW</NavLink></li>
                                    <li><NavLink to="/product/detail/2">灰鲸7kW</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                <h4>直流桩</h4>
                                <ul className='menus-pc-child__content--detail'>
                                    <li><NavLink to="/product/detail/3">白鲸30/40kW</NavLink></li>
                                    <li><NavLink to="/product/detail/4">塞鲸120kW</NavLink></li>
                                    <li><NavLink to="/product/detail/13">卡切拉特320kW</NavLink></li>
                                    <li><NavLink to="/product/detail/5">明克鲸360kW</NavLink></li>
                                    <li><NavLink to="/product/detail/6">领航鲸800kW</NavLink></li>
                                    <li><NavLink to="/product/detail/7">蓝鲸1250kW</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                <h4>液冷终端</h4>
                                <ul className='menus-pc-child__content--detail'>
                                    <li><NavLink to="/product/detail/8">600kW液冷超充终端</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h3>
                            海外交、直流桩/堆
                        </h3>
                        <ul className='menus-pc-child__content'>
                            <li>
                                <h4>交流桩</h4>
                                <ul className='menus-pc-child__content--detail'>
                                    <li><NavLink to="/product/detail/9">欧标基斯卡7/11/22kW</NavLink></li>
                                    {/* <li><NavLink to="/product/detail/10">欧标瓦吉塔7/11kW</NavLink></li> */}
                                    {/* <li><NavLink to="/">美标基斯卡7.6/11.5kW</NavLink></li> */}
                                    <li><NavLink to="/product/detail/10">美标瓦吉塔7.6/11.5kw</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                <h4>直流桩</h4>
                                <ul className='menus-pc-child__content--detail'>
                                    <li><NavLink to="/product/detail/11">欧标美标白鲸30/40kW</NavLink></li>
                                    <li><NavLink to="/product/detail/12">欧标美标明克鲸120/150/180/240kW</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </>)
}

export default MenusPc;