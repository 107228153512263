import { NavLink, NavDropdown } from 'react-bootstrap';
import classNames from 'classnames';

function focusMenu(name: string, location: any) {
    if (name === 'home') {
        return location.pathname === '/' ? 'focus' : undefined;
    }
    return location.pathname.includes(name) ? 'focus' : undefined;
}

function renderMenu(routes: any[], level: number, location: any, onMenuMouseEnter?: (e: any) => void, onMenuMouseLeave?: (e: any) => void) {
    let r: any = [];
    for (let i = 0; i < routes.length; i++) {
        let t1 = routes[i];
        if (t1.hidden) continue;
        if (Array.isArray(t1.children) && t1.children.length) {
            r.push(<NavDropdown className={classNames({ "nav-link__underline": i !== routes.length - 1 }, focusMenu(t1.path === "/" ? "home" : t1.path!, location))} onMouseLeave={level === 1 ? onMenuMouseLeave : () => { }} onMouseEnter={level === 1 ? onMenuMouseEnter : () => { }} key={t1.path} title={t1.title} data-type={t1.path.replace(/\//g, "")}>
                {renderMenu(t1.children, 2, location)}
            </NavDropdown>)
        }
        else {
            if (level === 1) {
                r.push(<NavLink onMouseEnter={onMenuMouseEnter} onMouseLeave={onMenuMouseLeave} key={t1.path} href={t1.path} className={classNames("nav-link__underline", focusMenu(t1.path === "/" ? "home" : t1.path!, location))} data-type={t1.path === "/" ? "home" : t1.path!.replace(/\//g, "")}>{t1.title}</NavLink>)
            }
            else {
                if (t1.hidden !== true) {
                    r.push(<NavDropdown.Item key={t1.path} href={t1.path}>{t1.title}</NavDropdown.Item>)
                }
            }
        }
    }
    return r;
}

export default renderMenu;