import Home from "./pages/Home";
import Introduce from "./pages/About/Introduce";
import Join from "./pages/About/Join";
import JoinDetail from "./pages/About/JoinDetail";
import JoinDetailEmploy from "./pages/About/JoinDetailEmploy";
import ProductList from "./pages/Product/List";
import ProductContent from "./pages/Product/Content";
import Solution from './pages/Solution';
import News from "./pages/News";
import NewsDetail from "./pages/News/Detail";
import CaseSharing from "./pages/CaseSharing";
import type { RouteObject } from "react-router-dom";



export type RouteObjectExtend = RouteObject & {
  hidden?: boolean;
  title?: string;
  children?: RouteObjectExtend[];
  component?: string;
};
//二级路由的第一个必须以list结尾
const routes: any[] = [
  {
    path: "/",
    title: "首页",
    element: <Home />,
  },
  {
    path: "/about",
    title: "关于我们",
    children: [
      {
        path: "/about/list",
        title: "企业介绍",
        element: <Introduce />,
      },
      {
        path: "/about/join",
        title: "加入我们",
        element: <Join />,
      },
      {
        path: "/about/join/detail",
        title: "招聘职位",
        element: <JoinDetail />,
      },
      {
        path: "/about/join/detail/:id",
        hidden: true,
        element: <JoinDetailEmploy />,
      },
    ],
  },
  {
    path: "/product",
    title: "产品中心",
    children: [
      {
        path: "/product/list",
        title: "主营产品中心",
        element: <ProductList />,
      },
      {
        path: '/product/domestic',
        title: '国标交、直流桩/堆',
        children: [{
          path: '/product/domestic/a1',
          title: '基斯卡7/21kW交流桩',
          element: <ProductContent id="0" />
        }, {
          path: '/product/domestic/a2',
          title: '瓦吉塔7kW交流桩',
          element: <ProductContent id="1" />
        }, {
          path: '/product/domestic/a3',
          title: '灰鲸7kW交流桩',
          element: <ProductContent id="2" />
        }, {
          path: '/product/domestic/a4',
          title: '白鲸30/40kW直流桩',
          element: <ProductContent id="3" />
        }, {
          path: '/product/domestic/a5',
          title: '塞鲸120kW直流桩',
          element: <ProductContent id="4" />
        }, {
          path: '/product/domestic/a10',
          title: '卡切拉特320kW直流桩',
          element: <ProductContent id="13" />
        }, {
          path: '/product/domestic/a6',
          title: '明克鲸360kW直流桩',
          element: <ProductContent id="5" />
        }, {
          path: '/product/domestic/a7',
          title: '领航鲸800kW直流桩',
          element: <ProductContent id="6" />
        }, {
          path: '/product/domestic/a8',
          title: '蓝鲸1250kW直流桩',
          element: <ProductContent id="7" />
        }, {
          path: '/product/domestic/a9',
          title: '600kW液冷超充终端直流桩',
          element: <ProductContent id="8" />
        }]
      },
      {
        path: '/product/foreign',
        title: '海外交、直流桩/堆',
        children: [
          {
            path: '/product/foreign/a1',
            title: '欧标基斯卡7/11/22kW交流桩',
            element: <ProductContent id="9" />
          }, {
            path: '/product/foreign/a2',
            title: '美标基斯卡7.6/11.5kW交流桩',
            element: <ProductContent id="10" />
          }, {
            path: '/product/foreign/a3',
            title: '欧标美标白鲸30/40kW直流桩',
            element: <ProductContent id="11" />
          }, {
            path: '/product/foreign/a4',
            title: '欧标美标明克鲸120/150/180/240kW直流桩',
            element: <ProductContent id="12" />
          }
        ]
      },
      {
        path: "/product/detail/:id",
        element: <ProductContent />,
        hidden: true
      }
    ],
  },
  {
    path: '/news',
    title: '新闻中心',
    element: <News />
  },
  {
    path: '/news/detail/:id',
    hidden: true,
    element: <NewsDetail />
  },
  {
    path: "/solution",
    title: "解决方案",
    element: <Solution />,
  },
  {
    path: "/caseSharing",
    title: "案例分享",
    element: <CaseSharing />,

  }
];

export default routes;
