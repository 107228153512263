import { Row, Col } from 'react-bootstrap';
import NavLink from '../../../components/NavLink';
import classNames from 'classnames';
import "./index.scss";

interface CarouselContentProps {
    url: string;
    data: any;
    contentLine?: number;
    className?: string;
    onClick?: () => void;
    linkUrl: string;
}

function CarouselContent(props: CarouselContentProps) {
    const { data, url, contentLine = 2, className, linkUrl } = props;
    const onRedirect = () => {
        window.location.href = linkUrl;
    }
    return (<Row onClick={onRedirect} className={classNames('align-items-center carouse-content', className)}>
        <Col className='carouse-content-left' md={6} style={{ backgroundImage: `url(${url})` }} />
        <Col md={6} className="content carouse-content-right">
            <div className="lh-b pb-2 pt-2 pt-md-0 ft-14 ftcolor-black50">{data.createTime.split(' ')[0].replace(/-/g, '.')}</div>
            <h3 className='ftbold-18 my-2 bl-1'>{data.title}</h3>
            <div className='content ft-14 ftcolor-black50 pb-lg-0 ftheight-1d5 text-start'>
                <div className={contentLine === 2 ? "bl-2" : 'bl-3'}>
                    {data.introduction}
                </div>
            </div>
            <NavLink className="d-none d-md-block" to={linkUrl ?? ""}>
                <button className='btn btn-primary-blue__ghost mt-jc-4'>了解更多</button>
            </NavLink>
        </Col>
    </Row>)
}

export default CarouselContent;