const PATHKEY = "path-key";

export const viewPortWidth = ()=> window.innerWidth || document.documentElement.clientWidth;
export const viewPortHeight = ()=> window.innerHeight || document.documentElement.clientHeight;

export function hasRouteRecord(){
   const item = sessionStorage.getItem(PATHKEY);
   return !!item;
}

//有且只增加两条记录
export function addRouteRecord(path:string){
    const paths = sessionStorage.getItem(PATHKEY) ?? "";
    let pathsArr = paths.split(",");
    if(pathsArr[1] && pathsArr[1]===path) return;
    pathsArr.push(path);
    if(pathsArr.length>2){
        pathsArr = pathsArr.slice(1);
    }
    sessionStorage.setItem(PATHKEY,String(pathsArr));
}

//取上一条路由记录
export function getPrevRoute(){
    const paths = sessionStorage.getItem(PATHKEY) ?? "";
    let pathsArr = paths.split(",");
    let current = window.location.pathname;
    if((pathsArr[1] ?? "/") !== current){
        return pathsArr[1] ?? "/";
    }
    return pathsArr[0] ?? "/";
}

export function getQueryString(name:string){
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)","i");
    let r = window.location.search.substring(1).match(reg);
    if(r!=null){
        return  decodeURI(r[2]);
    }
    return null;
}