import classNames from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import './index.scss'
type customImgType = {
  imgUrl: string;
  webpUrl?: string;
  className?: string;
  [key: string]: any;
};
const CustomImg = (props: customImgType) => {
  const { imgUrl, webpUrl: webpUrlProps, className } = props;
  const imgRef = useRef<any>();
  const [imgClassName,setImgClassName]=useState('d-block')
  const webpUrl = useMemo(() => {
    const url: string = imgUrl.replace(".png", ".webp");
    return webpUrlProps ? webpUrlProps : url;
  }, [imgUrl, webpUrlProps]);
  useEffect(() => {
    if (imgRef?.current) {
      imgRef.current.onload = () => {
        console.log('加载完成拉')
        setImgClassName('d-none')
      };
    }
  }, []);
  return (
    <div className={classNames("custom-img", className)}>
      <img src={webpUrl} alt="" className={classNames(imgClassName,'small-img')}/>
      <img src={imgUrl} alt="" ref={imgRef} />
    </div>
  );
};
export default CustomImg;
