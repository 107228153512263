import { useNavigate } from 'react-router-dom';
import { getPrevRoute, getQueryString } from '../../utils/util';
import "./index.scss";

function ReturnBack() {
  let prevRoute = getPrevRoute();
  const navigate = useNavigate();
  const param = getQueryString('redirect');

  if (prevRoute === "/" && !window.location.search.includes('redirect')) return null;
  const onBack = () => {
    if (param == "2") {
      sessionStorage.setItem('remember-scroll',"2");
      navigate(-1);
    }
    else {
      window.location.replace(prevRoute);
    }
  }
  return (<div className="return-back">
    <img onClick={onBack} src="/image/global/img_return.png" />
  </div>)
}

export default ReturnBack;