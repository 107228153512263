import type { ReactNode } from "react";
import classNames from "classnames";
import "./index.scss";
import CustomImg from "../CustomImg";

interface HeadWrapperProps {
  imgUrl: string;
  children?: ReactNode;
  title?: ReactNode;
  className?: string;
  ptNone?: boolean;
  mode?:number;
  desc?: ReactNode;
}

function HeadWrapper(props: HeadWrapperProps) {
  const { imgUrl, title, children, className, ptNone, mode=1,desc} = props;
  return (
    <div
      className={classNames(
        "header-wrapper",
        { "header-wrapper-ptnone": ptNone },
        className
      )}
    >
      <div
        className="header-wrapper-content"
        style={{ backgroundImage: `url(${imgUrl})` }}
      >
        {/* <img src={imgUrl} alt="" className="d-none d-md-block" /> */}
        <CustomImg imgUrl={imgUrl} className="d-none d-md-block"/>
        <div className="header-wrapper-content--title">{title}</div>
        {mode==2&&<div className="header-wrapper-content--des">
          {desc}
        </div>}
      </div>
      {children}
    </div>
  );
}

export default HeadWrapper;
