import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import "./index.scss";

interface CountContentItemProps {
    imgUrl: string;
    title: string;
    number: string;
    unit: string;
    isAfter?: boolean;
}

function CountContentItem(props: CountContentItemProps) {
    const { imgUrl, title, number, unit, isAfter } = props;
    const countItemNumRef = useRef<any>();
    useEffect(() => {
        //@ts-ignore
        var counterUp = window.counterUp["default"];
        //@ts-ignore
        new Waypoint({
            element: countItemNumRef.current,
            handler: function () {
                if(!countItemNumRef.current) return;
                //@ts-ignore
                counterUp(countItemNumRef.current, {
                    duration: 1000,
                    delay: 50
                })
                this.destroy()
            },
            offset: 'bottom-in-view',
        });
    }, [])
    return (<div className={classNames("count-content-item d-flex align-items-center", { "count-content-item__after": isAfter })}>
        <div className="count-content-item__left d-none d-md-flex flex-column align-items-center">
            <img src={imgUrl} alt="" />
            <div>{title}</div>
        </div>
        <div className="count-content-item__right d-flex flex-column">
            <div className='d-block d-md-none count-content-item__right--title text-center'>{title}</div>
            <div ref={countItemNumRef} className="count-content-item__right--number text-right">{number}</div>
            <div className="count-content-item__right--unit text-end">{unit}</div>
        </div>
    </div>)
}

function CountContent() {
    return (<div className="count-content d-flex">
        <div className='count-content-introduce text-center d-none d-md-block'>
            鲸充<br />目前已实现
        </div>
        <CountContentItem isAfter imgUrl="/image/count/img_count1.png" title="充电桩数" number="10000+" unit="(根)" />
        <CountContentItem isAfter imgUrl="/image/count/img_count2.png" title="充电站数" number="600+" unit="(座)" />
        <CountContentItem isAfter imgUrl="/image/count/img_count3.png" title="总充电量" number="10+" unit="(亿)" />
        <CountContentItem imgUrl="/image/count/img_count4.png" title="碳减排" number="65+" unit="(吨)" />
    </div>)
}

export default CountContent;