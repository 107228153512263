import { CellTitleHead } from '../../../components/CellTitle';
import ImgProductionPng from "../../../assets/about/img_production1.png";
import ImgProduction2Png from "../../../assets/about/img_production2.png";
import ImgProduction3Png from "../../../assets/about/img_production3.png";
import "./index.scss";

function Introduce() {
    return (<div className="introduce-page">
        <div className="introduce-page-content">
            <CellTitleHead className='introduce-page-content-title' title="关于鲸充" english="ABOUT JING CHARGING">
                <p>鲸充新能源是一家集智能充电设备研发、制造、销售；充电网的投建及运营；汽车充电大数据挖掘和增值服务于一体的新能源技术企业。</p>
                <p>公司成立于2019年3月，总部位于南京南站绿地之窗，工厂位于南京市溧水区工业园区内，总占地面积24亩，其中办公面积约2000㎡，生产基地占地面积约5000㎡，
                    新投资建设二期厂房约15000㎡。目前职工总数 200余人，其中研发人员30余人。鲸充在江苏、上海、浙江、安徽、河南、广东等地拥有30家分子公司，
                    与众多大型企业建立了良好、长期的合作关系。</p>
                <p>公司成立至今，已实现10000多个运营终端，600余座运营场站，覆盖全国多地，碳减排达65万吨。鲸充始终秉承“创新驱动，科技引领，务实笃行，抱诚守真”核心价值观，以“以科技创新，与万物共生”为使命， 致力于成为最受依赖的新能源充电生产运营服务商，让全球客户享受中国精益智造。未来，
                    鲸充将积极布局充电场站，加强互联互通、合作创新，为更多新能源汽车车主提供更加智能、高效、便携、节能的综合充电 服务，推动新能源汽车充电行业不断发展！</p>
            </CellTitleHead>
        </div>
        <div className="introduce-page-display introduce-page-display--pc d-none d-md-block">
            <img src={ImgProductionPng} alt="" />
        </div>
        <div className='introduce-page-display-mobile d-block d-md-none'>
            <div className="introduce-page-display">
                <img src={ImgProduction2Png} alt="" />
            </div>
            <div className="introduce-page-display">
                <img src={ImgProduction3Png} alt="" />
            </div>
        </div>
    </div>)
}

export default Introduce;